class Navigation {
  mobileTrigger = document.querySelector('.navigation__mobile-trigger')

  navigation = document.querySelector('.navigation')

  navigationParents = document.querySelectorAll('.navigation__link--is-parent')

  navigationEmptyLinks = document.querySelectorAll('.navigation__link[href="#"], .navigation__sublist-link[href="#"]')

  isMenuOpen = false

  static MOBILE_OPEN_CLASS = 'is-open'

  static DROPDOWN_ACTIVE_CLASS = 'is-active'

  constructor() {
    this.onMobileTriggerClick = this.onMobileTriggerClick.bind(this)

    this.setUpEvents()
    this.disableEmptyLinks()
  }

  setUpEvents() {
    this.mobileTrigger.addEventListener('click', this.onMobileTriggerClick)

    this.navigationParents.forEach((item) => item.addEventListener('click', this.onParentClick))

    document.body.addEventListener('click', () => {
      if (window.innerWidth < 1024 && this.isMenuOpen) this.close()

      if (window.innerWidth >= 1024) {
        const activeParents = document.querySelectorAll('.navigation__item.is-active')

        activeParents.forEach((parent) => parent.classList.remove(Navigation.DROPDOWN_ACTIVE_CLASS))
      }
    })
  }

  onParentClick(event) {
    event.stopPropagation()
    event.preventDefault()

    const { target } = event
    const parent = target.parentElement

    parent.classList.add(Navigation.DROPDOWN_ACTIVE_CLASS)
  }

  onMobileTriggerClick(event) {
    event.stopPropagation()

    if (!this.isMenuOpen) {
      this.open()
    } else {
      this.close()
    }
  }

  open() {
    this.isMenuOpen = true

    this.navigation.classList.add(Navigation.MOBILE_OPEN_CLASS)
  }

  close() {
    this.isMenuOpen = false

    this.navigation.classList.remove(Navigation.MOBILE_OPEN_CLASS)
  }

  disableEmptyLinks() {
    this.navigationEmptyLinks.forEach(link => {
      link.addEventListener('click', (event) => event.preventDefault())
    })
  }
}

new Navigation()
export default Navigation
